import React from "react";

import Layout from "../components/layout";
import PartnersComp from "../components/partners/partners";

export default function Partners() {
  return (
    <Layout>
      <div className="quotes-container">
        <PartnersComp />
      </div>
    </Layout>
  );
}
