import React from "react";
import Fade from "react-reveal/Fade";
import { FormattedMessage } from "gatsby-plugin-intl";
import { QuoteIntlProps } from "Src/interfaces/interfaces";

export type QuoteProps = QuoteIntlProps;

export default function Partner({ quotes, author, description, company, tech }: QuoteProps) {
  const [toggle, setToggle] = React.useState(false);
  const quoteElementId = author.toLowerCase().split(" ").join("_");

  const quoteIcon = (direction: "left" | "right") => (
    <div className="has-text-centered">
      <span className="icon">
        <i className={`fas fa-quote-${direction}`} />
      </span>
    </div>
  );

  const renderAuthor = () => (
    <div className="py-5">
      <p className="has-text-centered has-text-grey-dark has-text-weight-bold is-size-4 py-2">{company}</p>
      <p className="has-text-centered has-text-grey-dark is-size-5 pb-2">{author}</p>
    </div>
  );

  const renderQuote = () => (
    <div className="has-text-grey-dark is-centered has-text-centered is-multiline ml-5">
      {renderAuthor()}
      <div className="columns is-size-5 has-text-grey-dark is-centered is-multiline mb-1">
        {quotes.map((q, i) => (
          <div key={`quote_${i}`} className="column is-5 m-4">
            <div className="">
              {quoteIcon("left")}
              {q}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderDescription = () => (
    <div className="is-centered has-text-centered is-multiline">
      {renderAuthor()}
      <div className="is-size-5 has-text-grey-dark is-centered">
        {description}
        <div className="columns is-centered">
          <div className="column is-narrow">
            <div className="tags is-centered py-3">
              {tech?.map((t, i) => (
                <span key={`tech_${i}`} className="tag is-info">
                  {t}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="columns mx-0 is-centered has-text-centered my-6">
      <Fade bottom duration={1000}>
        <div className="column my-4 pb-6 is-three-quarters" id={quoteElementId}>
          {toggle ? renderDescription() : renderQuote()}
          <button
            className="button is-small mt-3"
            type="button"
            onClick={() => {
              setToggle(!toggle);
              const quoteElement = document?.getElementById(quoteElementId);
              // Scroll to top of quote element each time "read more" button is pressed
              quoteElement?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            }}
          >
            {toggle ? <FormattedMessage id="partners.readMoreButton.goBack" /> : <FormattedMessage id="partners.readMoreButton.readMore" />}
          </button>
        </div>
      </Fade>
    </div>
  );
}
