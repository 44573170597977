import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { QuoteIntlProps } from "Src/interfaces/interfaces";
import GetIntlArrayObject from "Src/utils/GetIntlArrayObject";
import GetIntlArrayPrimitive from "Src/utils/GetIntlArrayPrimitive";
import GetIntlSinglePrimitive from "Src/utils/GetIntlSinglePrimitive";

import Partner from "./partner";

export default function Partners() {
  const intl = useIntl();

  const title = GetIntlSinglePrimitive(intl, "partners.title");
  const partners = GetIntlArrayObject("partners.pa", intl.messages);

  const partnersComplete: QuoteIntlProps[] = partners.map((partner) => {
    const quotes = GetIntlArrayPrimitive(`partners.pa${partner.index}.quotes.q`, intl.messages);
    const tech = GetIntlArrayPrimitive(`partners.pa${partner.index}.tech.t`, intl.messages);
    return { author: partner.author, description: partner.description, company: partner.company, quotes: quotes, tech: tech };
  });

  return (
    <div className="d-content quotes">
      <div className="quotes__title">
        <h2 className="title mt-6 pt-6 is-size-1 is-size-2-mobile has-text-centered orange-color-text">{title}</h2>
      </div>
      <div style={{ position: "relative" }}>
        <div className="navigation-wrapper">
          {partnersComplete.map((quote, idx) => (
            <div key={`quote_${idx}`}>
              <Partner {...quote} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
